<template>
    <div class="ant-card" id="course_category">
        <a-spin v-if="$route.meta.isShow" :spinning="spinning" size="large">
            <div class="ant-card-body">
                <a-alert message="销售数据汇总支持查询当日到2小时前的数据。" type="warning" show-icon style="margin-bottom: 30px"/>
                <div class="head-box">
                    <div class="table-operator">
                        <Treeselect @treeDataChange="treeDataChange" :isDateShow="isDateShow" style="width:210px;" class="margin_right20"></Treeselect>
                        <a-input v-model="form.curriculum" style="width:210px;" placeholder="课程/商品关键字" class="margin_right20"/>
                        <a-range-picker v-model="form.dateVal" @change="onChangeDate" style="width: 210px;" class="margin_right20"/>
                        <a-button type="primary" class="margin_right20" @click="resetClick">重置</a-button>
                        <a-button type="primary" class="margin_right20" @click="init">导出</a-button>
                    </div>
                </div>
            </div>
            <div style="margin-top: 220px">
                <a-empty description="点击导出按钮可直接导出数据" />
            </div>
        </a-spin>
        <router-view/>
    </div>
</template>

<script>
import Treeselect from "@/components/channel/treeselectCodeCheck";
import axios from "axios";
import {domainName} from "@/config/index";
export default {
    components: {Treeselect},
    data() {
        return {
            spinning: false,
            tableLoading: false,
            isDateShow:true,
            form:{
                channel:[],
                curriculum:'',
                startTime:'',
                endTime:'',
                dateVal:[],
            },
        };
    },
    mounted() {
    },
    methods: {

        // 点击重置
        resetClick(){
            this.form.channel = []
            this.form.curriculum = ''
            this.form.startTime = ''
            this.form.endTime = ''
            this.form.dateVal = []
            this.isDateShow = false
        },

        // 搜索·推广人所属渠道
        treeDataChange(data){
            this.form.channel = data
            this.isDateShow = true
        },

        init(){
            let token = localStorage.getItem("token");
            this.tableLoading = true
            let urlLink = domainName
            if(urlLink != "https://test.shenyiedu.com/"){
                urlLink = 'https://admin.shenyiedu.com/'
            }
            let url = `${urlLink}adminv2/usercenter/agentSales`
            let obj = {
                codes: this.form.channel.toString(),
                businessName: this.form.curriculum,
                startTime: this.form.startTime,
                endTime: this.form.endTime,
            }
            axios.post(url,obj,{
                responseType: 'blob',
                headers:{
                    'accesstoken':token,
                    'authorization':token
                }
            }).then((res) => {
                this.tableLoading = false
                let blob = res.data;
                const link = document.createElement('a');
                blob = new Blob([blob], {type: 'application/x-excel'});
                link.style.display = 'none';
                link.href = URL.createObjectURL(blob);
                link.download = "渠道销售数据.xls";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
        },

        // 搜索 - 修改时间
        onChangeDate(date, dateString) {
            if (dateString[0] != "") {
                this.form.startTime = dateString[0];
                this.form.endTime = dateString[1];
            } else {
                this.form.startTime = "";
                this.form.endTime = "";
            }
        },
    }
};
</script>

<style lang="less" scoped>
.table-operator{
    display: flex;
}
/deep/ .ant-input{
    height: 36px !important;
}
/deep/ .ant-select-selection--single{
    height: 36px !important;
}
</style>
